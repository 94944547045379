<template>
    <div>
        <v-expansion-panels v-model="panels" multiple>
            <v-expansion-panel>
                <v-expansion-panel-header color="rfaccent2" class="white--text title font-weight-light">
                    My Sent Requests
                    <template v-slot:actions>
                        <v-icon color="white">
                            mdi-chevron-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-n6 mb-n4">
                    <v-container>
                        <v-row justify="end">
                            <v-col cols="12" lg="3" class="pt-1 pb-2">
                                <v-text-field
                                    v-model="searchSent"
                                    label="Search"
                                    prepend-inner-icon="mdi-magnify"
                                    solo
                                    :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'white'"
                                    clearable
                                    dense
                                    hide-details
                                    class="mt-1"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-data-table
                        :headers="headersSent"
                        :items="sentRequests"
                        :loading="loadingSent"
                        :options.sync="optionsSent"
                        :server-items-length="totalSentRequests"
                        class="elevation-0"
                    >
                        <template v-slot:item.repeater="{ item }">
                            <RepeaterStatusIcon :status="item.repeater.Status"/>

                            <router-link :to="'/repeater/' + item.repeater.ID" class="no-underline color-inherit">{{
                                    item.repeater.Name
                                }}
                            </router-link>
                        </template>

                        <template v-slot:item.to="{ item }">
                            <div v-if="item.to && item.to.Username">
                                <UserAvatarList
                                    :user="item.to"
                                    xsmall
                                />

                                <router-link :to="'/user/' + item.to.Username" class="no-underline color-inherit">
                                    {{ item.to.Username }}
                                </router-link>&nbsp;
                                <small>
                                    <router-link :to="'/license/' + item.to.Callsign"
                                                 class="no-underline color-inherit">{{ item.to.Callsign }}
                                    </router-link>
                                </small>
                            </div>
                        </template>

                        <template v-slot:item.id="{ item }">
                            <div class="text-right">
                                <v-btn color="rfaccent2" dark small @click="openReviewRequest(item.id)">
                                    View Request
                                </v-btn>
                            </div>
                        </template>

                        <template v-slot:item.approved="{ item }">
                            <v-icon v-if="item.approved === 1" color="success" class="ml-4">mdi-check</v-icon>
                            <v-icon v-else-if="item.approved === 0" color="error" class="ml-4">mdi-close</v-icon>
                            <v-icon v-else color="info" class="ml-4">mdi-clock-outline</v-icon>
                        </template>

                        <template v-slot:item.date="{ item }">
                            {{ $moment(item.date).format('M/D/YYYY h:mm a') }}
                        </template>
                    </v-data-table>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
            <!-- <v-expansion-panel v-if="!config.USE_INBOX"> -->
                <v-expansion-panel-header color="rfaccent2" class="white--text title font-weight-light">
                    Repeater Access Requests
                    <template v-slot:actions>
                        <v-icon color="white">
                            mdi-chevron-down
                        </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-n6 mb-n4">
                    <v-tabs v-model="tab" icons-and-text grow>
                        <v-tab>Pending Requests
                            <!--                                <v-icon color="info">mdi-new-box</v-icon>-->
                            <v-icon color="info">mdi-clock-outline</v-icon>
                        </v-tab>
                        <v-tab>Approved Requests
                            <v-icon color="success">mdi-check</v-icon>
                        </v-tab>
                        <v-tab>Denied Requests
                            <v-icon color="error">mdi-close</v-icon>
                        </v-tab>

                        <v-tab-item v-for="n in 3" :key="n">
                            <v-container>
                                <v-row justify="end">
                                    <v-col cols="12" lg="3" class="pt-1 pb-2">
                                        <v-text-field
                                            v-model="search"
                                            label="Search"
                                            prepend-inner-icon="mdi-magnify"
                                            solo
                                            :background-color="$vuetify.theme.dark ? 'grey darken-3' : 'white'"
                                            clearable
                                            dense
                                            hide-details
                                            class="mt-1"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>

                            <v-data-table
                                :headers="headers"
                                :items="requests[n - 1]"
                                :loading="loading"
                                :options.sync="options"
                                :server-items-length="totalRequests[n - 1]"
                                class="elevation-0"
                            >
                                <template v-slot:no-data>
                                    You have no <span class="text-lowercase">{{ getRequestType(n - 1) }}</span>
                                    requests to display
                                </template>

                                <template v-slot:no-results>
                                    No results match your search
                                </template>

                                <template v-slot:item.repeater="{ item }">
                                    <RepeaterStatusIcon :status="item.repeater.Status"/>

                                    <router-link :to="'/repeater/' + item.repeater.ID"
                                                 class="no-underline color-inherit">{{
                                            item.repeater.Name
                                        }}
                                    </router-link>
                                </template>

                                <template v-slot:item.from="{ item }">
                                    <div v-if="item.from && item.from.Username">
                                        <UserAvatarList
                                            :user="item.from"
                                            xsmall
                                        />
                                        <!--                                    <router-link :to="'/user/' + item.from.Username">{{ item.from.Username }}</router-link>&nbsp;-->
                                        <!--                                    <small>(<router-link :to="'/license/' + item.from.Callsign">{{ item.from.Callsign }}</router-link>)</small>-->

                                        <router-link :to="'/user/' + item.from.Username"
                                                     class="no-underline color-inherit">
                                            {{ item.from.Username }}
                                        </router-link>&nbsp;
                                        <small>
                                            <router-link :to="'/license/' + item.from.Callsign"
                                                         class="no-underline color-inherit">{{ item.from.Callsign }}
                                            </router-link>
                                        </small>
                                    </div>
                                </template>

                                <template v-slot:item.id="{ item }">
                                    <div class="text-right">
                                        <v-btn color="rfaccent2" dark small @click="openReviewRequest(item.id)">
                                            View Request
                                        </v-btn>
                                    </div>
                                </template>

                                <template v-slot:item.approved="{ item }">
                                    <v-icon v-if="item.approved === 1" color="success" class="ml-4">mdi-check
                                    </v-icon>
                                    <v-icon v-else-if="item.approved === 0" color="error" class="ml-4">mdi-close
                                    </v-icon>
                                    <v-icon v-else color="info" class="ml-4">mdi-clock-outline</v-icon>
                                </template>

                                <template v-slot:item.date="{ item }">
                                    {{ $moment(item.date).format('M/D/YYYY h:mm a') }}
                                </template>
                            </v-data-table>
                        </v-tab-item>
                    </v-tabs>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog v-model="dialogs.review.state" persistent max-width="1200">
            <v-card>
                <v-toolbar color="rfaccent2" dense dark>
                    <v-toolbar-title>Review Request</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="closeReviewDialog">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-list v-if="dialogs.review.request && dialogs.review.request.repeater">
                        <v-row>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-subtitles-outline</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater.Name
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Repeater Name</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-radio-fm</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ dialogs.review.request.repeater.Frequency.replace('46X', '462') }}
                                            MHz
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Frequency</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-login-variant</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater['PL In']
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Input Tone</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-logout-variant mdi-rotate-180</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.repeater['PL Out']
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Output Tone</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                dialogs.review.request.from.FullName
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Licensee Name</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :to="'/user/' + dialogs.review.request.from.Username"
                                             target="_blank">
                                    <UserAvatarList
                                        :user="dialogs.review.request.from"
                                        small
                                        v-if="dialogs.review.request.from"
                                    />

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ dialogs.review.request.from.Username }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Username</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :to="'/license/' + dialogs.review.request.from.Callsign"
                                             target="_blank">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-certificate</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.from.Callsign
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Callsign</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-calendar</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                $moment(dialogs.review.request.date).format('MM/DD/YYYY h:mm a')
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Date Requested</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-account-group</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{ dialogs.review.request.members }} Total
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Family Members</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="3" class="ma-0 py-0 px-0">
                                <v-list-item two-line :href="`tel:${dialogs.review.request.phone}`">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-phone</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>{{
                                                dialogs.review.request.phone | formatPhone
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Phone Number</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" class="ma-0 py-0 px-0">
                                <v-list-item two-line :href="`mailto:${dialogs.review.request.from.Email}`">
                                    <v-list-item-avatar>
                                        <v-icon>mdi-email</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{
                                                dialogs.review.request.from.Email
                                            }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Email Address</v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-icon>
                                        <v-icon small>mdi-open-in-new</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-col>
                        </v-row>
                    </v-list>

                    <div v-if="dialogs.review.request">
                        <h3 class="font-weight-light my-2">Repeater Will Be Used For</h3>
                        <div>
                            <ul class="ml-10">
                                <li v-for="(use, index) in dialogs.review.request.uses.split(',')" :key="index">
                                    {{ use }}
                                </li>
                            </ul>
                        </div>

                        <h3 class="font-weight-light mt-5 mb-2">Additional Notes from User</h3>
                        <div class="mb-5">
                            <v-card color="grey lighten-4" light
                                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-10' : 'mx-1'">
                                <v-card-text>
                                    {{ dialogs.review.request.notes }}
                                </v-card-text>
                            </v-card>
                        </div>
                    </div>

                    <v-textarea
                        v-model="dialogs.review.response"
                        label="Detailed Response"
                        hint="This will be sent to the user along with your approval decision. Give the user some extra information about the repeater or give them a reason why you're denying the request."
                        persistent-hint
                        outlined
                        v-if="dialogs.review.request && (dialogs.review.request.to === user.id && this.$_.isNull(dialogs.review.request.approved))"
                    >
                    </v-textarea>

                    <div v-else>
                        <h3 class="font-weight-light mt-5 mb-2">Response from Owner</h3>
                        <div class="mb-5">
                            <v-textarea
                                v-model="dialogs.review.response"
                                label="Detailed Response"
                                hint="This will be sent to the user along with your approval decision. Give the user some extra information about the repeater or give them a reason why you're denying the request."
                                persistent-hint
                                outlined
                                v-if="dialogs.review.request && (dialogs.review.request.to === user.id && !this.$_.isNull(dialogs.review.request.approved))"
                            ></v-textarea>

                            <v-card
                                color="grey lighten-4"
                                light
                                :class="$vuetify.breakpoint.lgAndUp ? 'mx-10' : 'mx-1'"
                                v-else
                            >
                                <v-card-text v-text="dialogs.review.response"></v-card-text>
                            </v-card>
                        </div>
                    </div>

                </v-card-text>
                <v-card-actions>
                    <div class="row my-0">
                        <div v-if="dialogs.review.request && dialogs.review.request.to === user.id" class="col-12">
                            <div class="col-12 text-center"
                                 v-if="isApproved(dialogs.review.request) || isDenied(dialogs.review.request)">
                                <v-chip
                                    :color="isApproved(dialogs.review.request) ? 'success' : 'error'"
                                >
                                    <v-icon>
                                        {{
                                            isApproved(dialogs.review.request) ? 'mdi-check' : 'mdi-close'
                                        }}
                                    </v-icon>
                                    Request&nbsp;<span
                                    v-text="isApproved(dialogs.review.request) ? 'Approved' : 'Denied'"></span>
                                </v-chip>
                            </div>

                            <div class="mx-2 text-center"
                                 v-if="isApproved(dialogs.review.request) || isDenied(dialogs.review.request)">
                                <span>
                                    <em>You have already <span
                                        v-text="isApproved(dialogs.review.request) ? 'approved' : 'denied'"></span> this request. Edit the Response from Owner above and click below to change your decision.</em>
                                </span>
                            </div>

                            <div class="d-flex justify-end flex-wrap mt-5">
                                <v-btn
                                    color="error"
                                    :class="$vuetify.breakpoint.smAndDown ? 'my-1' : 'mx-1 my-1'"
                                    :disabled="isDenied(dialogs.review.request)"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    @click="confirmDenyRequest"
                                >
                                    Deny
                                </v-btn>

                                <v-btn
                                    color="success"
                                    :class="$vuetify.breakpoint.smAndDown ? 'my-1' : 'mx-1 my-1'"
                                    :disabled="isApproved(dialogs.review.request)"
                                    :block="$vuetify.breakpoint.smAndDown"
                                    @click="confirmApproveRequest"
                                >
                                    Approve
                                </v-btn>
                            </div>
                        </div>

                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.confirmApprove.state" max-width="600">
            <v-card>
                <v-card-title>Approval Confirmation</v-card-title>
                <v-card-text class="text-center">
                    Are you sure you want to approve this user's request? An email will be sent to them with your
                    response.
                </v-card-text>

                <div class="mx-5 pb-5">
                    <v-btn color="rfaccent2" dark block class="my-1" @click="saveRequest(true)">Yes, confirm</v-btn>
                    <v-btn color="error" block @click="dialogs.confirmApprove.state = false">No, cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogs.confirmDeny.state" max-width="600">
            <v-card>
                <v-card-title>Denial Confirmation</v-card-title>
                <v-card-text class="text-center">Are you sure you want to deny this user's request? An email will be
                    sent to them with
                    your response.
                </v-card-text>
                <div class="mx-5 pb-5">
                    <v-btn color="rfaccent2" dark block class="my-1" @click="saveRequest(false)">Yes, confirm</v-btn>
                    <v-btn color="error" block @click="dialogs.confirmDeny.state = false">No, cancel</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<style>
    .no-underline {
        text-decoration: none;
    }

    .color-inherit {
        color: inherit !important;
    }
</style>

<script>
    import axios from 'axios';
    import config from '@/config';
    import RepeaterStatusIcon from '@/components/RepeaterStatusIcon.vue';
    import UserAvatarList from "@/components/UserAvatarList.vue";
    import { useTitle } from '@vueuse/core';

    export default {
        name: 'MyRequests',

        props: ['user'],

        components: {
            UserAvatarList,
            RepeaterStatusIcon
        },

        data: () => ({
            config: config,
            loading: false,
            showApprovedRequests: false,
            headers: [
                {text: 'Repeater Name', value: 'repeater', sortable: false},
                {text: 'Requested By', value: 'from', sortable: false},
                {text: 'Request Date', value: 'date'},
                {text: 'Approval', value: 'approved'},
                {text: '', value: 'id', sortable: false},
            ],
            panels: [0, 1],
            tab: 0,
            search: null,
            searchSent: null,
            requests: [
                [],
                [],
                [],
            ],
            totalRequests: [
                0,
                0,
                0,
            ],
            headersSent: [
                {text: 'Repeater Name', value: 'repeater', sortable: false},
                {text: 'Sent To', value: 'to', sortable: false},
                {text: 'Request Date', value: 'date'},
                {text: 'Approval', value: 'approved'},
                {text: '', value: 'id', sortable: false},
            ],
            options: {
                sortBy: ['date'],
                sortDesc: [true],
                mustSort: true
            },
            sentRequests: [],
            totalSentRequests: 0,
            loadingSent: false,
            optionsSent: {
                sortBy: ['date'],
                sortDesc: [true],
                mustSort: true
            },
            users: {},
            repeaters: {},
            dialogs: {
                review: {
                    state: false,
                    request: null,
                    response: null,
                    responseEditable: false,
                    responseEdit: null
                },
                confirmApprove: {
                    state: false
                },
                confirmDeny: {
                    state: false
                }
            },
            snackbar: {
                state: false,
                color: 'info',
                text: null,
                icon: null,
                timeout: 10000
            }
        }),

        filters: {
            formatPhone(phone) {
                phone = _.trim(phone);
                let countryCode = phone.substring(0, phone.length - 10);
                if (countryCode === '+1') countryCode = '';
                const areaCode = phone.substring(phone.length - 10, phone.length - 7);
                const exchange = phone.substring(phone.length - 7, phone.length - 4);
                const number = phone.substring(phone.length - 4, phone.length);
                return _.trim(`${countryCode} (${areaCode}) ${exchange}-${number}`);
            }
        },

        methods: {
            isPending(request) {
                return request.approved === null;
            },

            isApproved(request) {
                return request.approved === 1;
            },

            isDenied(request) {
                return request.approved === 0;
            },

            getRequestType(n) {
                return n === 0 ? 'Pending' : n === 1 ? 'Approved' : 'Denied';
            },

            openReviewDialog() {
                this.dialogs.review.state = true;
                this.$router.push({
                    name: 'profile-requests-repeaters-id',
                    params: {id: this.dialogs.review.request.id}
                });
            },

            closeReviewDialog() {
                this.dialogs.review.state = false;
                this.$router.push({name: 'profile-requests-repeaters'});
            },

            confirmApproveRequest() {
                //@todo validate a response was entered
                this.dialogs.confirmApprove.state = true;
            },

            confirmDenyRequest() {
                this.dialogs.confirmDeny.state = true;
            },

            saveRequest(approve) {
                this.dialogs.confirmApprove.state = false;
                this.dialogs.confirmDeny.state = false;

                const requestId = this.dialogs.review.request.id;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .put(config.API_LOCATION + '/request/repeater/' + requestId, {
                        approved: !!approve,
                        response: this.dialogs.review.response
                    }, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.fetchRequests();
                        this.closeReviewDialog();

                        if (response.data.success) {
                            this.$root.$emit('updaterequests');
                            this.snackbar.color = 'success';
                            this.snackbar.icon = 'mdi-check';
                            this.snackbar.text = 'The request details have been saved successfully.';
                            this.snackbar.state = true;
                        }
                    })
                    .catch(err => {
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = 'An error occurred while updating the request. ' + (err && err.response && err.response.data && err.response.data.message ? err.response.data.message : '');
                        this.snackbar.state = true;
                        console.error(err);

                        this.$root.$emit('updaterequests');
                    });
            },

            openReviewRequest(requestId) {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/request/repeater/' + requestId, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.dialogs.review.request = response.data;

                        this.dialogs.review.response = this.dialogs.review.request.response;

                        this.openReviewDialog();
                    })
                    .catch(err => {
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-alert';
                        this.snackbar.text = 'An error occurred while looking up the request details. Please try again later.';
                        this.snackbar.state = true;
                        console.error(err);
                    });
            },

            fetchRequests() {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                this.loading = true;

                const offset = ((this.options.page - 1) * this.options.itemsPerPage);
                axios
                    .get(`${config.API_LOCATION}/request/repeater?search=${this.search ? this.search : ''}&sort=${this.options.sortBy}&limit=${this.options.itemsPerPage > 0 ? this.options.itemsPerPage : 0}&skip=${offset}&descending=${this.options.sortDesc}&status=${this.tab === 0 ? 'new' : this.tab === 1 ? 'approved' : 'denied'}`, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.totalRequests[this.tab] = response.data.info.total;
                        this.requests[this.tab] = response.data.items;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.loading = false;

                        if (this.$route.params.id) {
                            this.openReviewRequest(this.$route.params.id);
                        }
                    });
            },

            fetchRequestsSent() {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                this.loadingSent = true;

                let offset = ((this.optionsSent.page - 1) * this.optionsSent.itemsPerPage);
                axios
                    .get(`${config.API_LOCATION}/request/repeater/sent?search=${this.searchSent ? this.searchSent : ''}&sort=${this.optionsSent.sortBy}&limit=${this.optionsSent.itemsPerPage}&skip=${offset}&descending=${this.optionsSent.sortDesc}`, {
                        headers: axiosHeaders
                    })
                    .then(async response => {
                        this.totalSentRequests = response.data.info.total;
                        this.sentRequests = response.data.items;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.loadingSent = false;

                        // if (this.$route.params.id) {
                        //     this.openReviewRequest(this.$route.params.id);
                        // }
                    });
            }
        },

        watch: {
            "user.authenticated"() {
                if (this.user.authenticated) {
                    this.fetchRequests();
                    this.fetchRequestsSent();
                }
            },

            options: {
                handler() {
                    this.fetchRequests();
                },
                deep: true,
            },

            optionsSent: {
                handler() {
                    this.fetchRequestsSent();
                },
                deep: true,
            },

            search() {
                this.fetchRequests();
            },

            searchSent() {
                this.fetchRequestsSent();
            },

            tab() {
                this.fetchRequests();
            }
        },

        mounted() {
            useTitle('My Requests - myGMRS.com');
        }
    }
</script>
